.config-document {
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: calc(var(--margin) * 0.25);
	align-items: center;
	padding: 10px;
	&__placeholder {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 12px;
		margin-top: 20vh;
	}
	&__document-wrapper {
		position: relative;
		border: 1px solid var(--color-border-90-light);
		margin: auto;
	}
	&__doc-description {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: calc(var(--margin) * 0.25);
		font-size: var(--font-size-1);
		font-weight: 500;
	}
	&__nodes-loading {
		position: absolute;
		top: 30%;
		left: 50%;
		transform: translateX(-50%);
	}
	&__loader-wrapper {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 30%;
		gap: 40px;
	}
}
.drop-item {
	display: flex;
	justify-content: center;
	border-radius: 2px !important;
	font-style: normal;
	font-weight: 400;
	.padding {
		padding: calc(var(--padding) * 0.25) calc(var(--padding) * 0.5);
	}
	.resize--text {
		justify-content: flex-start !important;
		align-items: center !important;
	}
	.resize {
		display: flex;
		justify-content: center;
		&-handle {
			> div {
				background-color: var(--color-esign-primary-light);
				border-radius: 100%;
				width: 12px !important;
				height: 12px !important;
			}
		}
		&--alignement{
			align-items: center !important;
			justify-content: flex-start !important;
		}
	}
	.resize--active {
		border-color: var(--color-esign-primary-light) !important;
	}
}
.node-item {
	position: relative;
	left: 50%;
	top: 50%;
	transform: translate(-50%, 0);
	transform-origin: center center;
	font-stretch: condensed;
	width: 100%;
}
.sign-node {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: calc(var(--margin) * 0.25);
}
.initial-node {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: calc(var(--margin) * 0.25);
}
.link-node {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: calc(var(--margin) * 0.25);
	padding: calc(var(--padding) * 0.25);
}

.email-node {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: calc(var(--margin) * 0.25);
}
.date-node {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: calc(var(--margin) * 0.25);
	padding: calc(var(--padding) * 0.25) calc(var(--padding) * 0.5);
	text-transform: capitalize;
}
.name-node {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: calc(var(--margin) * 0.25);
}
.checkbox-node {
	width: 20px;
	height: 20px;
}

.checkbox-node-safari {
	appearance: none;
	/* Remove default browser styling */
	-webkit-appearance: none;
	/* Safari-specific appearance reset */
	
	display: inline-block;
	/* Enable width and height adjustments */
	
	border: 1px solid #767676;
	/* Define border color */
	
	background-color: white;
	/* Set background color */
	
	position: relative;
	/* For positioning child elements */
	
	cursor: pointer;
	/* Pointer cursor on hover */
	
	border-radius: 0;
	/* No rounded corners */
}

.checbox-node-red {
	border-color: #FF0000 !important;
}

.text-node {
	font-weight: 400;
	border: none;
	outline: none;
	padding: calc(var(--padding) * 0.25) calc(var(--padding) * 0.5);
	width: fit-content;
	word-break: break-all;
	text-overflow: ellipsis;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
}
.resizing-div {
	display: flex;
	justify-content: center;
	align-items: center;
}
@media screen and (max-width: 860px) {
	.drawer {
		width: 196px;
	}
}

.hide {
	transition: 0.0000001s;
	transform: translateX(-999999999999px);
}
