//Paras: undo-modal css
.undo-modal {
    &__wrapper {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: fade-in($color: transparent, $amount: 0.6);
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        z-index: 4;
        user-select: none;
    }

    &__container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 8px;
        padding-right: 24px;
    }

    &__content {
        display: flex;
        width: 384px;
        flex-direction: column;
        align-items: flex-end;
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.10);
        position: absolute;
        animation: slide 100ms linear alternate;
        bottom: 1%;
    }

    @keyframes slide {
        0% {
          right: 0px;
        }
        50% {
          right: 384px;
        }
      }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 8px 16px;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        border-bottom: 1px solid var(--color-border-100-light);

        &_text {
            color: var(--color-text-100-light);
            font-size: 16px;
            font-weight: 600;
            line-height: var(--line-height-6);
        }
    }

    &__closeIcon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        font-size: 25px;
        color: #7C8398;
    }

    &__footer {
        display: flex;
        padding: 12px 16px;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        color: var(--color-text-90-light, #2F323D);

        &_timer {
            color: var(--color-esign-primary-light);
            font-weight: 500;
        }

        &_undo-btn {
            padding: 8px 12px;
            min-width: 54px;
            color: var(--color-text-80-light);
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            line-height: var(--line-height-2);
        }
    }
}

