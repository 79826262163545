.processing-document {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin: unset !important;
    max-width: unset !important;
    .esign-Modal__wrapper {
       border-radius: unset !important;
       height: 100%;
       padding-top: 20% !important;
    }
    .esign-circle-loader {
        height: unset !important;
    }

    &__step-description {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: hsla(0, 0%, 0%, 1);
    }
}